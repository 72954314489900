* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(#303b37, #1a1f1d);
  color: #e1eeeb;
  min-height: 100vh;
}

#header {
  background-color: black;
  text-align: center;
  border-bottom: white solid 0.2rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#header img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  background-color: transparent;
}

#header h2 {
  color: white;
  padding: 1rem;
  margin: 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem auto;
  padding: 1rem;
  max-width: 30rem;
  border-radius: 1rem ;
  background-color: aliceblue;
}

.input-group label {
  padding: 0.5rem;
  min-width: 3rem;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
}

.input-group textarea {
  padding: 0.4rem;
  margin: 0.5rem;
  font-size: 1.15rem;
  resize: vertical;
  border-radius: 0.5rem;
  min-height: 3rem;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
}
.input-group button {
  padding: 1rem;
  background-color: rgb(0, 0, 0);
  border: none;
  color: white;
  font-weight: bold;
  width: 100%;
  border-radius: 8px;
}

.input-group button:hover {
  cursor: pointer;
  border: 2px black solid;
}


.input-group label {
  display: block;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #76c0ae;
  border-radius: 0.25rem;
  background-color: transparent;
  color: #000000;
  font-size: 1rem;


}

@keyframes blink {
  0%, 100% {
      background-color: transparent;
  }
  50% {
      background-color: rgb(15 23 42);
  }
}
.input-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #76c0ae;
  border-radius: 0.25rem;
  background-color: transparent;
  color: #000000;
  font-size: 1rem;
}
#submit {
  padding: 0.5rem;
  margin: 0.5rem;
  text-align: center;
}


.custom-select select{
  appearance: none;
  width: 100%;
  font-size: 1rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

.custom-select select option{
  appearance: none;
  background-color: #eefffb;
  padding: 0.75rem;
  font-size: 1.5rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.custom-select select option:hover{
  appearance: none;
  background-color: #76c0ae;
}

.result-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  padding: 2rem;
  background-color: #ffffff;
}

.result-modal[open] {
  animation: slide-in-from-top 0.35s ease-out;
}

.result-modal::backdrop {
  background: rgba(0, 0, 0, 0.9);
}

.result-modal img {
  margin: 0 0 0.25rem 0;
  width: 5rem;
  height: 5rem;
}
.result-modal h3 {
  font-weight: bold;
  font-style: italic;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#saving-question-modal{
  position: fixed;
  top: 0;
  left: 0;
  
}